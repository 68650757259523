import React, { useRef, useState} from 'react'
import {ReactComponent as Arrow} from "../Assets/icons/back-arrow.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Table from "react-bootstrap/Table";

import Dropdown from "react-bootstrap/Dropdown";
import Metadata from "../Components/Health/Healthmetadata";

function HealthCompanyEmployeePolicy() {
    const navigate = useNavigate()
    const location = useLocation();
    const {employeeDetails} = location.state || {};
    console.log(employeeDetails, 'employeeDetails')
    const [filtereddata] = useState([]);
    const [showmetadata, setShowMetaData] = useState(false);
    const metaDetails = useRef({})


    function downloadImage(url, name) {
        fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert('An error sorry'));
    }


    const allpolicy = employeeDetails?.bulk_health.map((data, index) => {
        return (
            <tr key={index}>
                <td>{ index++}</td>
                <td>{data.enrollee.name}</td>
                <td>{data.plan}</td>
                <td>{data.amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' : data.status === "Completed" ? "text-warning" : 'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.policy_number}</td>
                <td>{data.end}</td>
                <td><span className={`${data.id_card === '' ? 'non-activelink' : 'idactivelink'}`}
                          onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')}>View ID</span>
                </td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy'
                        >
                            Manage
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className='healthdropdown' onClick={() => {
                                metaDetails.current = data;
                                setShowMetaData(true)
                            }}>View Details</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    })

    const filteredpolicy = filtereddata.map((data, index) => {
        return (
            <tr key={index}>
                <td>{ index++}</td>
                <td>{data.enrollee.name}</td>
                <td>{data.plan}</td>
                <td>{data.amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' : 'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.policy_number}</td>
                <td>{data.end}</td>
                <td><span className={`${data.id_card === '' ? 'non-activelink' : 'activelink'}`}
                          onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')}>View ID</span>
                </td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy'
                                         >
                            Manage
                        </Dropdown.Toggle>
                        {data.status === 'Active' ?
                            <Dropdown.Menu>
                                <Dropdown.Item className='healthdropdown' onClick={() => {
                                    metaDetails.current = data;
                                    setShowMetaData(true)
                                }}>View Details</Dropdown.Item>
                            </Dropdown.Menu> :
                            data.status === 'Incomplete' ?
                                <Dropdown.Menu>
                                    <Dropdown.Item className='healthdropdown' onClick={() => {
                                        metaDetails.current = data.metadata;
                                        setShowMetaData(true)
                                    }}>View Details</Dropdown.Item>
                                </Dropdown.Menu> :
                                data.status === 'Completed' ?
                                    <Dropdown.Menu>
                                        <Dropdown.Item className='healthdropdown' onClick={() => {
                                            metaDetails.current = data.metadata;
                                            setShowMetaData(true)
                                        }}>View Details</Dropdown.Item>
                                    </Dropdown.Menu> :
                                    data.status === 'Success' ?
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='healthdropdown' onClick={() => {
                                                metaDetails.current = data.metadata;
                                                setShowMetaData(true)
                                            }}>View Details</Dropdown.Item>
                                            <Dropdown.Item className='healthdropdown'>Contact Admin</Dropdown.Item>
                                        </Dropdown.Menu> :
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='healthdropdown'>Contact Admin</Dropdown.Item>
                                            <Dropdown.Item className='healthdropdown' onClick={() => {
                                                metaDetails.current = data.metadata;
                                                setShowMetaData(true)
                                            }}>View Details</Dropdown.Item>
                                        </Dropdown.Menu>
                        }
                    </Dropdown>
                </td>
            </tr>
        );
    })

    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow/>
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title pb-5">{employeeDetails?.company?.name || 'Company'} Details</h4>
                <div>
                    <div className="policy-tab-container">
                        <Table className="">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Customer</th>
                                <th>Plan</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>Policy Number</th>
                                <th>Due Date</th>
                                {/* <th>Frequency</th> */}
                                <th>Id Card</th>
                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                filtereddata.length !== 0 ? filteredpolicy : allpolicy
                            }
                            </tbody>
                        </Table>

                    </div>
                </div>
                <Metadata show={showmetadata} data={metaDetails} onHide={() => setShowMetaData(false)}/>
                {/*{isLoading ? <Loader/> : ''}*/}
                {/*{Loading ? <Loader/> : ''}*/}

            </div>
        </div>
    )
}

export default HealthCompanyEmployeePolicy
