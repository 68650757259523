import React, {useEffect, useRef, useState} from 'react'
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { usePolicy } from '../Hooks/policy';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import Loader from '../Components/Loader/Loader';
import { Cookies } from 'react-cookie';
import Emailsent from '../Components/modal/Email-modal';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import {useUnderwriter} from "../Hooks/underwriter";
import Metadata from "../Components/Health/Healthmetadata";

function Healthpolicy() {
    const navigate = useNavigate()
    const { healthpolicy, data, isLoading, individual_healthpolicy, Health_Resend_Email } = usePolicy()
  const [emailmodal, setEmailModal] = useState(false);
  const [filtereddata, setFiltereddata] = useState([]);
    const [showmetadata, setShowMetaData] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);
    const cookies = new Cookies()
    let itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount1 = Math.ceil(filtereddata.length / itemsPerPage);
    let getinfo = cookies.get('xirhnfo')


    function downloadImage(url, name){
        fetch(url)
          .then(resp => resp.blob())
          .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = name;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
          })
          .catch(() => alert('An error sorry'));
  }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    const handlePageClick1 = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filtereddata.length;
        setItemOffset(newOffset);
    };

    const searchcustomer = (e) => {
        let searchword = e.target.value
        const newfilter = data.filter((value) => {
            return value.policy.enrollee.toLowerCase().includes(searchword.toLowerCase());
        });
        setFiltereddata(newfilter);
    }

    const {
        healthplan,
        hmo_plan,
        hmo_states,
        state,

    } = useUnderwriter();
    const[previewmodal, setModal] = useState(false)

    const [customerinfo, setCustomerinfo] = useState({})
    const metaDetails = useRef({})
    const {renew_health_policy, isLoading: Loading} = usePolicy()

const renew_policy = (data) => {
        setModal(false)
    renew_health_policy(data, setEmailModal)

}
    const allpolicy = data.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.enrollee.name}</td>
                <td>{data.plan}</td>
                <td>{data.amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' : data.status === "Completed" ? "text-warning" :  'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.policy_number}</td>
                <td>{data.end}</td>
                <td ><span className={`${data.id_card === '' ? 'non-activelink' : 'idactivelink'}`} onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')} >View ID</span></td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy' onMouseOver={() => setCustomerinfo({...customerinfo,  user_id: data.enrollee.id, user_hmo_id: data.user_hmo_id })}>
                            Manage
                        </Dropdown.Toggle>
                        { data.status === 'Active' ?
                            <Dropdown.Menu>
                                <Dropdown.Item  className='healthdropdown' onClick={() => setModal(true)} >Renew Policy</Dropdown.Item>
                                {data.bulk_health && (
                                    <Dropdown.Item className='healthdropdown' onClick={() => {
                                        metaDetails.current = data;
                                        setShowMetaData(true)
                                        navigate('/policy/health/companyEmployee', {state: {employeeDetails: data}})
                                    }}>View Company employee</Dropdown.Item>
                                )}
                                <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                            </Dropdown.Menu> :
                            data.status === 'Incomplete' ?
                                <Dropdown.Menu>
                                    <Dropdown.Item  className='healthdropdown' onClick={() => Health_Resend_Email(data.user_hmo_id, setEmailModal)} >Resend Payment Link</Dropdown.Item>
                                    <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                </Dropdown.Menu> :
                                data.status === 'Completed' ?
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className='healthdropdown' onClick={() => setModal(true)} >Renew Policy</Dropdown.Item>
                                        {data.bulk_health && (
                                            <Dropdown.Item className='healthdropdown' onClick={() => {
                                                metaDetails.current = data;
                                                setShowMetaData(true)
                                                navigate('/policy/health/companyEmployee', {state: {employeeDetails: data}})
                                            }}>View Company employee</Dropdown.Item>
                                        )}
                                        <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                    </Dropdown.Menu> :
                                    data.status === 'Success' ?
                                        <Dropdown.Menu>
                                            <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                            {data.bulk_health && (
                                                <Dropdown.Item className='healthdropdown' onClick={() => {
                                                    metaDetails.current = data;
                                                    setShowMetaData(true)
                                                    navigate('/policy/health/companyEmployee', {state: {employeeDetails: data}})
                                                }}>View Company employee</Dropdown.Item>
                                            )}
                                            <Dropdown.Item  className='healthdropdown' >Contact Admin</Dropdown.Item>
                                        </Dropdown.Menu> :
                                        <Dropdown.Menu>
                                            <Dropdown.Item  className='healthdropdown' >Contact Admin</Dropdown.Item>
                                        </Dropdown.Menu>
                        }
                    </Dropdown>
                </td>
            </tr>
        );
    })

    const filteredpolicy = filtereddata.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.enrollee.name}</td>
                <td>{data.plan}</td>
                <td>{data.amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' :  'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.policy_number}</td>
                <td>{data.end}</td>
                <td ><span className={`${data.id_card === '' ? 'non-activelink' : 'activelink'}`} onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')} >View ID</span></td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy' onMouseOver={() => setCustomerinfo({...customerinfo,  user_id: data.enrollee.id, user_hmo_id: data.user_hmo_id })}>
                            Manage
                        </Dropdown.Toggle>
                        { data.status === 'Active' ?
                            <Dropdown.Menu>
                                <Dropdown.Item  className='healthdropdown' onClick={() => setModal(true)} >Renew Policy</Dropdown.Item>
                                <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                            </Dropdown.Menu> :
                            data.status === 'Incomplete' ?
                                <Dropdown.Menu>
                                    <Dropdown.Item  className='healthdropdown' onClick={() => Health_Resend_Email(data.user_hmo_id, setEmailModal)} >Resend Payment Link</Dropdown.Item>
                                    <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                </Dropdown.Menu> :
                                data.status === 'Completed' ?
                                    <Dropdown.Menu>
                                        <Dropdown.Item  className='healthdropdown' onClick={() => setModal(true)} >Renew Policy</Dropdown.Item>
                                        {data.bulk_health && (
                                            <Dropdown.Item className='healthdropdown' onClick={() => {
                                                metaDetails.current = data;
                                                setShowMetaData(true)
                                                navigate('/policy/health/companyEmployee', {state: {employeeDetails: data}})
                                            }}>View Company employee</Dropdown.Item>
                                        )}
                                        <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                    </Dropdown.Menu> :
                                    data.status === 'Success' ?
                                        <Dropdown.Menu>
                                            <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                            {data.bulk_health && (
                                                <Dropdown.Item className='healthdropdown' onClick={() => {
                                                    metaDetails.current = data;
                                                    setShowMetaData(true)
                                                    navigate('/policy/health/companyEmployee', {state: {employeeDetails: data}})
                                                }}>View Company employee</Dropdown.Item>
                                            )}
                                            <Dropdown.Item  className='healthdropdown' >Contact Admin</Dropdown.Item>
                                        </Dropdown.Menu> :
                                        <Dropdown.Menu>
                                            <Dropdown.Item  className='healthdropdown' >Contact Admin</Dropdown.Item>
                                            <Dropdown.Item  className='healthdropdown' onClick={() => {metaDetails.current = data; setShowMetaData(true)}} >View Details</Dropdown.Item>
                                        </Dropdown.Menu>
                        }
                    </Dropdown>
                </td>
            </tr>
        );
    })

    useEffect(() => {
        // let getinfo = cookies.get('xirhnfo')
        if (getinfo !== undefined) {
            let info = JSON.parse(atob(getinfo))
            // console.log(info);
            individual_healthpolicy(info.enrollee_id)
        } else {
            healthpolicy()
        }
        hmo_plan();
        hmo_states()
        // healthpolicy()
    }, [ getinfo, healthpolicy, hmo_states, hmo_plan,  individual_healthpolicy])
    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Health Policy</h4>
                <div>
                    <div className="policy-search">
                        <input type="text" placeholder='Search By Name' onChange={searchcustomer} />
                        <div>Filter</div>
                    </div>
                    <div className="policy-tab-container">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Customer</th>
                                    <th>Plan</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>Policy Number</th>
                                    <th>Due Date</th>
                                    {/* <th>Frequency</th> */}
                                    <th>Id Card</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filtereddata.length !== 0 ? filteredpolicy : allpolicy
                                }
                            </tbody>
                        </Table>
                        {
                            filtereddata.length !== 0 ?
                                <ReactPaginate
                                    breakLabel="..."
                                    previousLabel={<Previcon />}
                                    nextLabel={<Nexticon />}
                                    pageCount={pageCount1}
                                    onPageChange={handlePageClick1}
                                    containerClassName={"paginatecontainer"}
                                    previousLinkClassName={"prevbtn"}
                                    pageClassName={"pageli"}
                                    nextLinkClassName={"nextbtn"}
                                    disabledClassName={"paginationdisabled"}
                                    activeClassName={"paginationactive"}
                                    breakClassName={"breakli"}
                                    renderOnZeroPageCount={null}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                /> :
                                <ReactPaginate
                                    breakLabel="..."
                                    previousLabel={<Previcon />}
                                    nextLabel={<Nexticon />}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"paginatecontainer"}
                                    previousLinkClassName={"prevbtn"}
                                    pageClassName={"pageli"}
                                    nextLinkClassName={"nextbtn"}
                                    disabledClassName={"paginationdisabled"}
                                    activeClassName={"paginationactive"}
                                    breakClassName={"breakli"}
                                    renderOnZeroPageCount={null}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                />
                        }
                    </div>
                </div>
                <Emailsent show={emailmodal} onHide={() => setEmailModal(false)}/>
                <Metadata show={showmetadata} data={metaDetails}  onHide={() => setShowMetaData(false)}/>
                <PreviewQuote show={previewmodal} healthplan={healthplan} state={state} customer={customerinfo} renewpolicy={(data) => renew_policy(data)} setemail={() => setEmailModal(false) } onHide={() => setModal(false)}  />
                {isLoading ? <Loader /> : ''}
                {Loading ? <Loader /> : ''}

            </div>
        </div>
    )
}

export default Healthpolicy

function PreviewQuote(props) {
    // console.log(props.quote);
    // const [emailmodal, setEmailModal] = useState(false);

    const [healthdata, setHealthData] = useState({})
    const [customerinfo, setCustomerInfo] = useState({})
    const {
        hmo_hospital,
        hospital,
        hmo_residence_lga,
        residencelga,
        error

    } = useUnderwriter();
    // const {renew_health_policy, isLoading} = usePolicy()
    const getresidencelga = async (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        setCustomerInfo({ ...customerinfo, state: dataset.name })
        let StateID = e.target.value;
        await hmo_residence_lga(StateID);
    };

    const gethospitalbylga = async (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        let data = {
            PlanId: customerinfo.id,
            StateId: `${healthdata.state_of_residence}`,
            LgaId: dataset.id,
        };
        setHealthData({
            ...healthdata,
            [e.target.name]: JSON.parse(e.target.value),
        });

        await hmo_hospital(data);

    };


    const plan = (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: e.target.value,
        })
        setCustomerInfo({ ...customerinfo, id: dataset.id })
    }

    const sethospital = (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: e.target.value,
        });
        setCustomerInfo({ ...customerinfo, preferredhospital: dataset.name });
    };
    const renewPolicy = async () => {
        let data = {
            "user_id": props.customer.user_id,
            "plan_id": healthdata.plan_id.toString(),
            "payment_frequency": healthdata.payment_frequency,
            "state_of_residence": healthdata.state_of_residence,
            "lga_of_residence": healthdata.lga_of_residence,
            "hospital_id": healthdata.hospital_id.toString(),
            "user_hmo_id": props.customer.user_hmo_id
        }
        console.log(data)
        await props.renewpolicy(data)
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Renew Policy</h6>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Available Plans </label>
                        <select
                            name="plan_id"
                            onChange={plan}
                        >
                            <option defaultValue="" disabled selected>
                                Select Provider
                            </option>
                            {props.healthplan.map((data, index) => {
                                return (
                                    <option key={index} value={data.PlanId} data-id={data.PlanId}>
                                        {data.Name} {data.MonthlyCost}/{data.AnnualCost}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <label>State of Residence</label>
                        <select name="state_of_residence" onChange={getresidencelga}>
                            <option>Select State</option>
                            {props.state.map((data, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={data.StateID}
                                        data-name={data.Name}
                                    >
                                        {data.Name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <label>LGA of Residence</label>
                        <select name="lga_of_residence" onChange={gethospitalbylga}>
                            <option>Select LGA</option>
                            {residencelga.map((data, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={data.LgaID}
                                        data-id={data.LgaID}
                                    >
                                        {data.Name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Choose preferred hospital</label>
                        <select name="hospital_id" onChange={sethospital}>
                            <option>Select Hospital</option>
                            {hospital.map((data, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={data.ID}
                                        data-name={data.HospitalName}
                                    >
                                        {data.HospitalName}
                                    </option>
                                );
                            })}
                        </select>
                        <p className="text-danger">
                            <em>
                                {error === "unable to get hospitals" ? "No available hospital" : error}
                            </em>
                        </p>
                    </div>
                    <div>
                        <label>Payment frequency</label>
                        <select
                            name="payment_frequency"
                            onChange={(e) =>
                                setHealthData({
                                    ...healthdata,
                                    [e.target.name]: JSON.parse(e.target.value),
                                })
                            }
                        >
                            <option>Select Payment frequency</option>
                            <option value="1">1 Month</option>
                            <option value="2">2 Months</option>
                            <option value="3">3 Months</option>
                            <option value="4">4 Months</option>
                            <option value="5">5 Months</option>
                            <option value="6">6 Months</option>
                            <option value="7">7 Months</option>
                            <option value="8">8 Months</option>
                            <option value="9">9 Months</option>
                            <option value="10">10 Months</option>
                            <option value="11">11 Months</option>
                            <option value="12">12 Months</option>

                        </select>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className={`next-box `}>
                    <div onClick={() => { renewPolicy()}} >Continue</div>
                </div>
                {/*<Emailsent show={emailmodal} onHide={() => setEmailModal(false)}/>*/}
                {/*{isLoading ? <Loader /> : ''}*/}
            </Modal.Body>
        </Modal>
    )
}
